@charset 'UTF-8';
@import "/Users/alivevn/Website/vhost/shop-ritsubi-new/src/scss/utility/_media-queries.scss";
@import "/Users/alivevn/Website/vhost/shop-ritsubi-new/src/scss/utility/_mixin.scss";


.container-670{
  max-width: 700px;
  margin: 0 auto;
  padding: 0 15px;
}
.the-ttl{
  @include ffM;
  @include font(20,'',0.14,'');
  text-align: center;
}
.the-subttl{
  @include font(15,'',0.1,300);
  font-family: $cormorant;
  text-align: center;
  margin-bottom: 38px;
  @include SP{
    margin-bottom: 15px;
  }
}
a.normal-btn,
.normal-btn{
  @include font(14,40,0.08,bold);
  width: 156px;
  text-align: center;
  display: block;
  margin: 0 auto;
  background-color: $blue;
  position: relative;
  color: #fff;
  @include SP{
    letter-spacing: 0.04em;
    width: 141px;
  }
  &:after{
    @include absolute(calc(50% - 3px),'',7px,'',5px,5px);
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    @include transform_c(rotate(45deg));
    @include SP{
      @include absolute(calc(50% - 2px),'',12px,'',4px,4px);
    }
  }
}
.mv{
  position: relative;
  .txt{
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 0;
    @include font(12,'',0.1,'');
    width: 100%;
    background-color: #F5F5F5;
    @include SP{
      @include font(11,'',0.05,'');
      padding: 11px 0;
      text-align: center;
    }
  }
}

.banner-register{
  @include aspect-ratio(1844,398);
  background: url(../../img/top/banner01.jpg) center/cover no-repeat;
  max-width: 922px;
  width: 100%;
  margin: 0 auto 38px;
  display: block;
  @include SP{
    @include aspect-ratio(653,368);
    background-image: url(../../img/top/banner01_sp.jpg);
    margin-bottom: 15px;
  }
}
.banner-exuviance{
  @include aspect-ratio(1200,256);
  background: url(../../img/top/banner02.jpg) center/cover no-repeat;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  display: block;
  @include SP{
    @include aspect-ratio(650,182);
    background-image: url(../../img/top/banner02_sp.jpg);
  }
}

.banner-course{
  @include aspect-ratio(1200,256);
  background: url(../../img/top/banner03.jpg) center/cover no-repeat;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  display: block;
  @include SP{
    @include aspect-ratio(671,183);
    background-image: url(../../img/top/banner03_sp.jpg);
  }
}

.lst-ranking-product{
  max-width: 1024px;
  font-size: 0;
  margin: 0 auto;
  counter-reset: item;
  @include SP{
    margin: 0 -7px;
    width: calc(100% + 14px) !important;
  }
  .item{
    width: calc(20% - 24px);
    display: inline-block;
    vertical-align: top;
    counter-increment: item;
    margin: 0 12px;
    @include SP{
      width: calc(33.33% - 15px);
      margin: 0 7px;
    }
    a{
      display: block;
    }
    .img{
      text-align: center;
      margin-bottom: 4px;
      @include SP{
        margin-bottom: 10px;
      }
      img{
        max-width: 147px;
        margin: 0 auto;
        width: 100%;
        @include SP{
          max-width: 88px;
        }
      }
    }
    .name{
      @include font(15,22,0.04,'');
      margin-bottom: 4px;
      @include SP{
        @include font(14,21,0.04,'');
        @include multiLineEllipsis(2);
        margin-bottom: 2px;
      }
    }
    .price{
      color: $blue;
      @include ffM;
      &__number{
        @include font(14,28,0.04,'');
        display: inline-block;
        vertical-align: top;
      }
      .unit{
        @include font(12,26,0.04,'');
        display: inline-block;
        vertical-align: top;
      }
    }
  }
  &.is-ranking{
    .item{
      @include SP{
        &:nth-child(n+4){
          display: none;
        }
      }
      a{
        padding-top: 46px;
        position: relative;
        &:before{
          @include absolute(0,'','',calc(50% - 22px),43px,38px);
          background: url(../../img/common/icon/ico_crown_gold.svg) center/cover no-repeat;
        }
        &:after{
          @include absolute(14px,'','',50%,'','');
          content: counter(item);
          font-size: 16px;
          @include ffM;
          @include transform_c(translateX(-50%));
          color: #fff;
        }
      }
    }
  }
  &.is-slider{
    .item{
      width: 100%;
    }
  }
}

.sec-products,
.sec-voice{
  background: url(../../img/top/bg01.jpg) center/cover no-repeat;
}

.sec-highlight{
  padding: 62px 25px 59px;
  @include SP{
    padding-top: 43px;
    padding-bottom: 43px;
  }
  text-align: center;
  .container-670{
    max-width: 670px;
    padding: 0;
  }
  .sec-ttl{
    @include font(15,'',0.16,'');
    text-align: center;
    @include ffM;
    position: relative;
    margin-bottom: 30px;
    @include SP{
      font-size: 14px;
      margin-bottom: 13px;
    }
    &:before{
      @include absolute(50%,'','',0,100%,1px);
      background-color: $colorborder;
    }
    span{
      display: inline-block;
      background-color: #fff;
      padding: 0 18px;
      position: relative;
      z-index: 1;
    }
  }
  .lst{
    padding: 0 10px;
    text-align: left;
    margin-bottom: 70px;
    @include SP{
      padding: 0;
      margin-bottom: 52px;
    }
    .item{
      font-size: 0;
      margin-bottom: 17px;
      @include SP{
        margin-bottom: 13px;
      }
      a{
        display: block;
      }
      .time, .txt{
        display: inline-block;
        vertical-align: top;
        @include SP{
          display: block;
        }
      }
      .time{
        @include font(11,19,0.04,'');
        @include ffM;
        color: $gold;
        width: 65px;
        @include SP{
          width: 100%;
          margin-bottom: 2px;
        }
      }
      .txt{
        @include font(13,19,0.06,'');
        width: calc(100% - 65px);
        padding-left: 16px;
        text-align: justify;
        @include SP{
          width: 100%;
          padding-left: 0px;
        }
      }
    }
  }
  .sec-btn{
    display: inline-block;
    width: 100%;
    max-width: 460px;
    text-align: center;
    border: 1px solid $lightgold;
    padding: 27px 10px;
    position: relative;
    margin-bottom: 60px;
    @include SP{
      padding-top: 21px;
      padding-bottom: 21px;
      margin-bottom: 28px;
    }
    &:before{
      @include absolute(calc(50% - 3px),'',20px,'',5px,5px);
      border-top: 1px solid $colortext;
      border-right: 1px solid $colortext;
      @include transform_c(rotate(45deg));
      @include SP{
        width: 6px;
        height: 6px;
      }
    }
    span{
      @include font(16,'',0.16,'');
      @include ffM;
      display: inline-block;
      padding-left: 30px;
      background: url(../../img/common/icon/ico_link.svg) left center/14px 22px no-repeat;
      @include SP{
        font-size: 14px;
        background-size: 16px 22px;
      }
    }
  }
  .txt-register{
    @include font(14,'',0.05,'');
    text-align: center;
    margin-bottom: 36px;
  }
}

.sec-special{
  padding-bottom: 85px;
  @include SP{
    padding-bottom: 8px;
  }
  .container-1000{
    @include SP{
      padding: 0;
    }
  }
  .lst{
    font-size: 0;
    @include SP{
      position: relative;
      &:before{
        @include absolute(0,'',50%,'',1px,100%);
        background-color: #ddd;
      }
    }
    .item{
      width: calc(25% - 10px);
      display: inline-block;
      vertical-align: top;
      @include PC{
        margin-right: 13px;
        &:nth-child(4n){
          margin-right: 0;
        }
      }
      @include SP{
        width: 50%;
      }
      .txt{
        @include font(15,22,0.06,'');
        padding: 20px 20px 0;
        text-align: justify;
        @include SP{
          @include font(14,'',0.08,'');
          padding: 15px 15px 28px;
        }
      }
    }
  }
}

.sec-products{
  padding: 62px 0;
  @include SP{
    padding: 52px 0 30px;
  }
  .the-subttl{
    margin-bottom: 34px;
    @include SP{
      margin-bottom: 20px;
    }
  }
  .lst-label{
    font-size: 0;
    .item{
      display: inline-block;
      background-color: #ECEBEB;
      @include font(16,'',0.08,'');
      @include ffM;
      padding: 22px 10px 16px;
      text-align: center;
      width: 46.6%;
      transition: 0.5s ease;
      cursor: pointer;
      @include SP{
        font-size: 14px;
        padding: 17px 10px;
      }
      &.active{
        background-color: #fff;
        width: 53.4%;
      }
      &:first-child{
        border-top: 2px solid $blue;
      }
      &:nth-child(2){
        border-top: 2px solid $gold;
      }
    }
  }
  .lst-product{
    background-color: #fff;
    font-size: 0;
    @include flexbox();
    @include flex-wrap();
    box-shadow: 5px 5px 10px rgba(0,0,0,0.1);

    .item{
      width: 25%;
      @include flexbox();
      @include align-items();
      position: relative;
      @include SP{
        width: 50%;
      }
      @include PC{
        &:nth-child(-n+4){
          &:before{
            @include absolute('',-1px,'',9px,calc(100% - 18px),1px);
            background-color: #E8E8E8;
          }
        }
        &:not(:nth-child(-n+4)){
          &:before{
            @include absolute(0px,'','',9px,calc(100% - 18px),1px);
            background-color: #E8E8E8;
          }
        }
        &:not(:nth-child(4n)){
          &:after{
            @include absolute(9px,'',0,'',1px,calc(100% - 18px));
            background-color: #E8E8E8;
          }
        }
      }
      @include SP{
        &:nth-child(-n+2){
          &:before{
            @include absolute('',-1px,'',9px,calc(100% - 18px),1px);
            background-color: #E8E8E8;
          }
        }
        &:not(:nth-child(-n+2)){
          &:before{
            @include absolute(0px,'','',9px,calc(100% - 18px),1px);
            background-color: #E8E8E8;
          }
        }
        &:not(:nth-child(2n)){
          &:after{
            @include absolute(9px,'',0,'',1px,calc(100% - 18px));
            background-color: #E8E8E8;
          }
        }
      }
      a{
        display: block;
        width: 100%;
        padding: 15px 5px 15px 15px;
        @include SP{
          padding: 11px 13px 6px 6px;
        }
      }
      .img,
      .limit{
        display: inline-block;
        vertical-align: middle;
      }
      .img{
        width: 52px;
        @include SP{
          width: 48px;
        }
      }
      .limit{
        width: calc(100% - 52px);
        padding-left: 10px;
        @include SP{
          width: calc(100% - 48px);
          padding-left: 8px;
        }
      }
      .txt{
        @include font(16,'',0.025,'');
        @include ffM;
        @include multiLineEllipsis(2);
        @include SP{
          @include font(14,21,'','');
        }
      }
    }
  }
}

.sec-ranking{
  padding: 45px 10px 78px;
  @include SP{
    padding-top: 40px;
    padding-bottom: 53px;
  }
  .process{
    @include aspect-ratio(458,131);
    background: url(../../img/top/process.svg) center/cover no-repeat;
    max-width: 458px;
    margin: 0 auto 55px;
    @include SP{
      @include aspect-ratio(676,214);
      max-width: 338px;
      background-image: url(../../img/top/process_sp.svg);
      margin-bottom: 37px;
    }
  }
  .sec-btns{
    font-size: 0;
    max-width: 700px;
    margin: 0 auto 100px;
    @include SP{
      margin-bottom: 54px;
    }
    .btn{
      width: calc(50% - 5px);
      display: inline-block;
      vertical-align: top;
      background-color: $blue;
      @include font(14,'',0.1,bold);
      color: #fff;
      text-align: center;
      padding: 19px 10px;
      position: relative;
      @include SP{
        width: calc(50% - 3px);
        padding: 12px 10px 11px;
      }
      &:after{
        @include absolute(calc(50% - 3px),'',15px,'',6px,6px);
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        @include transform_c(rotate(45deg));
        @include SP{
          @include absolute('','',13px,'',5px,5px);
        }
      }
      &:nth-child(2n){
        margin-left: 10px;
        @include SP{
          margin-left: 6px;
        }
      }
    }
  }
  .the-subttl{
    margin-bottom: 32px;
  }
}

.sec-voice{
  padding: 69px 15px 54px;
  @include SP{
    padding: 47px 15px 38px;
  }
  .the-subttl{
    margin-bottom: 20px;
  }
  .lst-voice{
    max-width: 1050px;
    margin: 0 auto 23px;
    padding: 0 15px;
    @include flexbox();
    position: relative;
    @include max-screen(599px){
      padding: 0 5px;
      margin-bottom: 14px;
    }
    .owl-item,
    .owl-stage{
      @include flexbox();
    }
    .owl-nav{
      position: absolute;
      width: 100%;
      top: calc(50% - 6px);
      left: 0;
      @include clearfix();
      .owl-prev,
      .owl-next{
        span{
          font-size: 0;
          width: 12px;
          height: 12px;
          display: block;
          position: relative;
          &:after{
            @include absolute(0,'','',0,12px,12px);
            border-top: 1px solid #9E9C9A;
            border-right: 1px solid #9E9C9A;
            transition: 0.5s ease;
            @include SP{
              @include absolute('','','','',9px,9px);
            }
          }
        }
      }
      .owl-prev{
        float: left;
        span{
          &:after{
            @include transform_c(rotate(-135deg));
            @include SP{
              left: -8px;
            }
          }
        }
        @include PC{
          &:hover{
            span{
              &:after{
                left: -5px;
              }
            }
          }
        }
      }
      .owl-next{
        float: right;
        span{
          &:after{
            @include transform_c(rotate(45deg));
            @include SP{
              left: 8px;
            }
          }
        }
        @include PC{
          &:hover{
            span{
              &:after{
                left: 5px;
              }
            }
          }
        }
      }
    }
    .owl-dots{
      @include PC{
        display: none;
      }
      @include SP{
        text-align: center;
        margin-top: 19px;
        .owl-dot{
          width: 35px;
          height: 4px;
          background-color: #C5C4C2;
          margin: 2px;
          &.active{
            background-color: $gold;
          }
        }
      }
    }
    .item{
      margin: 0 10px;
      @include flexbox();
      @include max-screen(599px){
        margin: 0;
      }
      a{
        display: block;
        background-color: #fff;
        padding: 18px 20px 22px;
        @include SP{
          padding: 24px 20px;
        }
      }
      .info{
        font-size: 0;
        margin-bottom: 10px;
        @include SP{
          margin-bottom: 18px;
        }
        .img,
        .txt{
          display: inline-block;
          vertical-align: middle;
        }
        .img{
          width: 80px;
        }
        .txt{
          width: calc(100% - 80px);
          padding-left: 20px;
          @include SP{
            padding-left: 11px;
          }
          .name{
            @include font(15,22,0.04,'');
            @include ffM;
            @include multiLineEllipsis(2);
            text-align: justify;
            // height: 44px;
            @include SP{
              @include font(14,21,'','');
              // height: auto;
            }
          }
          .stars{
            @include font(14,'',0.06,'');
            color: $gold;
            margin-bottom: 4px;
            @include SP{
              font-size: 12px;
              margin-bottom: 3px;
            }
          }
          .time{
            @include font(12,20,0.06,'');
            color: $gold;
          }
        }
      }
      .intro{
        @include font(12,20,0.06,'');
        text-align: justify;
      }
    }
  }
}

.two-sec{
  font-size: 0;
  padding: 84px 0 103px;
  max-width: 1020px;
  margin: 0 auto;
  @include SP{
    padding: 46px 0 0;
  }
  .sec-column, .sec-news{
    width: calc(50% - 40px);
    display: inline-block;
    vertical-align: top;
    @include PC{
      &:nth-child(2n){
        margin-left: 80px;
      }
    }
    @include SP{
      width: 100%;
      padding-left: 25px;
      padding-right: 25px;
    }
    .the-subttl{
      margin-bottom: 15px;
      @include SP{
        margin-bottom: 5px;
      }
    }
    .normal-btn{
      width: 141px;
      &:after{
        right: 10px;
      }
    }
  }
}

.sec-column{
  @include SP{
    padding-bottom: 55px;
  }
  .lst-column{
    margin-bottom: 17px;
    .item{
      border-bottom: 1px solid #ddd;
      a{
        display: block;
        padding: 10px;
        @include SP{
          padding: 10px 0;
        }
      }
      .img,
      .txt{
        display: inline-block;
        vertical-align: middle;
      }
      .img{
        width: 80px;
      }
      .txt{
        width: calc(100% - 80px);
        padding-left: 15px;
        .ttl{
          @include multiLineEllipsis(2);
          @include font(15,22,0.06,'');
          text-align: justify;
          margin-bottom: 3px;
        }
        .time{
          @include ffM;
          @include font(12,'',0.04,'');
          color: $gold;
        }
      }
    }
  }
}

.sec-news{
  @include SP{
    background-color: #F9F9F9;
    padding-top: 40px;
    padding-bottom: 35px;
  }
  .lst-news{
    margin-bottom: 17px;
    .item{
      border-bottom: 1px solid #ddd;
      a{
        display: block;
        min-height: 100px;
        padding: 10px;
        @include flexbox();
        justify-content: center;
        flex-direction: column;
        @include SP{
          padding: 12px 0;
          min-height: 0;
        }
      }
      .time{
        @include ffM;
        @include font(12,'',0.04,'');
        color: $gold;
        margin-bottom: 5px;
      }
      .ttl{
        @include font(15,22,0.06,'');
        text-align: justify;
        margin-bottom: 3px;
      }
    }
  }
}

.sec-check{
  padding: 0 15px 96px;
  @include SP{
    padding: 50px 15px 60px;
  }
  .the-subttl{
    margin-bottom: 28px;
  }
  .lst-ranking-product{
    margin-bottom: 98px;
    @include SP{
      margin-bottom: 70px;
    }
  }
  .owl-item{
    @include flexbox();
  }
  .owl-dots{
    text-align: center;
    margin-top: 25px;
    @include SP{
      margin-top: 28px;
    }
    .owl-dot{
      width: 35px;
      height: 4px;
      background-color: #C5C4C2;
      margin: 2px;
      &.active{
        background-color: $gold;
      }
    }
  }
}

.sec-guide{
  font-size: 0;
  .the-subttl{
    border-bottom: 2px solid $blue;
    margin-bottom: 40px;
    padding-bottom: 23px;
    @include SP{
      margin-bottom: 40px;
      padding-bottom: 17px;
    }
  }
  .lst-guide, .calendar{
    display: inline-block;
    vertical-align: top;
    @include SP{
      display: block;
    }
  }
  .lst-guide{
    width: calc(100% - 290px);
    padding-right: 15px;
    @include SP{
      width: 100%;
      padding-right: 0;
    }
    .lst{
      max-width: 620px;
      font-size: 0;
      .item{
        width: 33.33%;
        display: inline-block;
        position: relative;
        &:nth-child(-n+3){
          &:before{
            @include absolute('',-1px,'',9px,calc(100% - 18px),1px);
            background-color: #E8E8E8;
          }
        }
        &:not(:nth-child(-n+3)){
          &:before{
            @include absolute(0px,'','',9px,calc(100% - 18px),1px);
            background-color: #E8E8E8;
          }
        }
        &:after{
          @include absolute(9px,'',0,'',1px,calc(100% - 18px));
          background-color: #E8E8E8;
        }
        a{
          display: block;
          @include ffM;
          @include font(16,'',0.06,'');
          text-align: center;
          padding: 12px 10px 115px;
          @include SP{
            @include font(15,'',0.04,'');
          }
        }
        &:nth-child(3n+1){
          a{
            position: relative;
            &:after{
              @include absolute(9px,'','',0,1px,calc(100% - 18px));
              background-color: #E8E8E8;
            }
          }
        }
        &--order{
          a{
            background: url(../../img/common/icon/ico_order.svg) center bottom 24px/49px 66px no-repeat;
          }
        }
        &--ship{
          a{
            background: url(../../img/common/icon/ico_ship.svg) center bottom 31px/68px 53px no-repeat;
          }
        }
        &--pay{
          a{
            background: url(../../img/common/icon/ico_pay.svg) center bottom 34px/69px 46px no-repeat;
          }
        }
        &--return{
          a{
            background: url(../../img/common/icon/ico_return.svg) center bottom 24px/35px 67px no-repeat;
          }
        }
        &--course{
          a{
            background: url(../../img/common/icon/ico_course.svg) center bottom 24px/73px 68px no-repeat;
          }
        }
        &--point{
          a{
            background: url(../../img/common/icon/ico_point.svg) center bottom 21px/60px 73px no-repeat;
          }
        }
        &:nth-child(n+4){
          a{
            padding-top: 23px;
          }
        }
      }
    }
  }
  .calendar{
    width: 290px;
    padding-top: 16px;
    @include SP{
      width: 100%;
    }
    &__ttl{
      @include font(15,'',0.14,'');
      text-align: center;
      @include ffM;
    }
  }
  .lst-link{
    text-align: center;
    background-color: #F5F5F5;
    font-size: 0;
    margin-top: 35px;
    padding: 20px 0px;
    .item{
      display: inline-block;
      padding: 0 17px;
      a{
        @include font(13,'',0.06,'');
      }
    }
  }
}