/* argument */
$border: #B1B0AE solid 1px;
$colorborder: #B1B0AE;
$colortext: #3D3935;
$widthcm: 1000px;

$blue: #00A2B0;
$gold: #B1A15C;
$lightgold: #D8D0AD;

$cormorant: 'Cormorant', serif;

$browser_prefix: ("-webkit-", "-moz-", "-ms-", "-o-", "");
@mixin add_prefix ($key, $value) {
	@each $prefix in $browser_prefix {
		#{$prefix}$key: $value;
	}
}

/* font style */
@mixin ffN {
	font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "ヒラギノ角ゴ W3", "メイリオ", "Osaka", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}
@mixin ffM {
	font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", serif;
}
@mixin ffYG {
	font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
}
@mixin ffYM {
	font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
}

/* site style */
@mixin text-hide{
	overflow: hidden;
	text-indent: -9999px;
}


/* [ opacity ]
-------------------------------------------------*/
@mixin opacity($num){
	-ms-filter: alpha(opacity=$num); /* IE8 */
	opacity: $num/100;
	//display: inline-block\9;
}

/* [ display style ]
-------------------------------------------------*/
@mixin display-table{
	display: table;
	> *{
		display: table-cell;
		vertical-align: middle;
	}
}
@mixin height-match{
	display: block;
	text-align: center;
	> *{
		vertical-align: middle;
		display: inline-block;
	}
	&:before{
		content:"";
		height: 100%;
		vertical-align: middle;
		width: 0;
		display: inline-block;
	}
}


/* [ background-image ]
-------------------------------------------------*/
@mixin bgimg($url,$rep:repeat,$pos:left top,$color:none,$size:auto) {
	background-image: url($url);
	background-repeat: $rep;
	background-position: $pos;
	background-color: $color;
	background-size: $size;
}


/* [ writing-mode ]
-------------------------------------------------*/
@mixin writing-mode( $orientation:'h', $direction:'rl' ) {
	@if $orientation == 'v' {
		// Vertical with direction, rl by default
		-ms-writing-mode: tb- + $direction; // IE
		@each $prefix in $browser_prefix {
			#{$prefix}writing-mode: vertical- + $direction;
		}
	} @else {
		// Default...
		-ms-writing-mode: lr-tb; // IE
		@each $prefix in $browser_prefix {
			#{prefix}writing-mode: horizontal-tb;
		}
	}
}

/* [ illustrator & photoshop letter spacing ]
-------------------------------------------------*/
@function letter-spacing( $letter-spacing ){
	@return ($letter-spacing/ 1000) * 1em;
}
@mixin letter-spacing( $target ){
	letter-spacing: letter-spacing( $target );
}


/* [ easy breakpoint ]
-------------------------------------------------*/
@mixin PC {
	@media (min-width: 897px) and (orientation: landscape),
		(min-width: 768px) and (orientation: portrait) {
		@content;
	}
}

@mixin SP {
	@media (max-width: 896px) and (orientation: landscape),
		(max-width: 767px) and (orientation: portrait) {
		@content;
	}
}

/* [ easy transform ]
-------------------------------------------------*/
@mixin transform($transforms) {
	@include add_prefix(transform, $transforms);
}

// Author: Kyohei Maeda

//aspect-ratio fixed box
//==================================================
@mixin aspect-ratio($width, $height) {
	position: relative;
	&:before{
		display: block;
		content: " ";
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}
	> .content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

//font-size with rem
//==================================================
@mixin font-size($size: 14) {
	font-size: $size + px;
	font-size: ($size / 10)  + rem;
}

@mixin my_menu_effect($items, $delay, $direct) {
	@each $item in $items {
		&:nth-child(#{$item}) {
			@include transition_c(#{$delay}ms, 'delay');
		}
		@if ($direct == "open") {
			$delay: $delay + 50;
		} @else {
			$delay: $delay - 50;
		}
	}
}

@mixin transition_c ($content, $opt: "") {
	@each $prefix in $browser_prefix {
		@if ($opt == "") {
			#{$prefix}transition: $content;
		} @else {
			#{$prefix}transition-#{$opt}: $content;
		}
	}
}

@mixin transform_c ($content) {
	@each $prefix in $browser_prefix {
		#{$prefix}transform: $content;
	}
}

@mixin calc-css ($prefix, $content) {
	#{$prefix}: -webkit-calc($content);
	#{$prefix}: -moz-calc($content);
	#{$prefix}: calc($content);
}

@mixin border ($content, $side: "") {
	@if ($side == "") {
		border: #{$content};
	} @else {
		border-#{$side}: #{$content};
	}
}

@mixin border-radius ($radius, $side: "") {
	@if ($side == "" ) {
		@each $prefix in $browser_prefix {
			#{$prefix}border-radius: $radius;
		}
	} @else {
		@each $prefix in $browser_prefix {
			#{$prefix}border-#{$side}-radius: $radius;
		}
	}
}

@mixin clearfix() {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

//==================================================
// Black and White Opacity Functions
// Call | .css { background: black(0.5); }
//==================================================
@function black($opacity) {
	@return rgba(black, $opacity)
}
@function white($opacity) {
	@return rgba(white, $opacity)
}

//==================================================
// Centering
// Call | .css { @include centerer; }
//==================================================

@mixin centerer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

// If you want to be able to center in only one direction...

@mixin centerer($horizontal: true, $vertical: true) {
	position: absolute;
	@if ($horizontal and $vertical) {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if ($horizontal) {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if ($vertical) {
		top: 50%;
		transform: translate(0, -50%);
	}
}

//==================================================
// Triangle helper mixin
// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
// @param {Color} $color [currentcolor] - Triangle color
// @param {Length} $size [1em] - Triangle size
// Call | .css::before { @include triangle(bottom, #000, 5px); }
//==================================================

@mixin triangle($direction, $color: currentcolor, $size: 1em, $distance: 5px) {
	@if not index(top right bottom left, $direction) {
		@error "Direction must be either `top`, `right`, `bottom` or `left`.";
	}

	width: 0;
	height: 0;
	content: '';
	z-index: 2;
	margin: $distance;
	border-#{opposite-position($direction)}: ($size * 1.5) solid $color;

	$perpendicular-borders: $size solid transparent;

	@if $direction == top or $direction == bottom {
		border-left:   $perpendicular-borders;
		border-right:  $perpendicular-borders;
	} @else if $direction == right or $direction == left {
		border-bottom: $perpendicular-borders;
		border-top:    $perpendicular-borders;
	}
}

// miltiple lines ellipsis
//==================================================
@mixin multiLineEllipsis($lineCount: 1){
	overflow: hidden;
	width: 100%;
	-webkit-line-clamp: $lineCount;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	white-space: normal;
}

// miltiple lines ellipsis for FF & IE
//==================================================
@mixin multiLineEllipsisForFF($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
	overflow: hidden;
	position: relative;
	line-height: $lineHeight;
	max-height: $lineHeight * $lineCount;
	text-align: justify;
	margin-right: -1.15em;
	padding-right: 1.15em;
	&:before {
		content: '…';
		position: absolute;
		right: 0;
		bottom: 0;
	}
	&:after {
		content: '';
		position: absolute;
		right: 0;
		width: 1.15em;
		height: 1em;
		margin-top: 0.2em;
		background: $bgColor;
	}
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		text-justify: inter-cluster;
	}
}

// FONT SMOOTH
//==================================================
@mixin font-smooth(){
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// FLEXBOX
//==================================================
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values: 1) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin flex-wrap($wrap: wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

@mixin align-items($align: center) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}

@mixin justify-content($direction: center) {
  -webkit-justify-content: $direction;
  -moz-justify-content: $direction;
  -ms-justify-content: $direction;
  justify-content: $direction;
}

@mixin flex-direction($direction: column) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}


// FONT
//==================================================
@mixin font($size: "", $lh: "", $letter: "", $fw: ""){
  @if ($size != ''){
    font-size: $size + px;
  }
  @if ($lh != ''){
    line-height: $lh + px;
  }
  @if ($letter != ''){
    letter-spacing: $letter + em;
  }
  @if ($fw != ''){
    font-weight: $fw;
  }
}

// DETECT
//==================================================
@mixin IE{
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

@mixin HOVER {
  @include min-screen(1025px){
    @content;
  }
}

@mixin IP5 {
  @include max-screen(350px){
    @content;
  }
}

@mixin ANDR {
  @include max-screen(370px){
    @content;
  }
}

// POSTITION ABSOLUTE
@mixin absolute($top: '', $bottom: '', $right: '', $left: '', $width: '', $height: ''){
  position: absolute;
  content: '';
  @if($top!=''){
    top: $top;
  }
  @if($bottom!=''){
    bottom: $bottom;
  }
  @if($right!=''){
    right: $right;
  }
  @if($left!=''){
    left: $left;
  }
  @if($width!=''){
    width: $width;
  }
  @if($height!=''){
    height: $height;
  }
}